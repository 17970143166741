import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { MENU_NAVIGATION_COMPONENT_IDS } from './consts';
import { getRole } from 'root/utils/manifestUtils';
import type { ConnectedComponentsBuilder } from '@wix/app-manifest-builder';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  widgetBuilder.set({
    displayName: 'Widget Menu Navigation',
    nickname: 'Menu NavigationWidget',
  });

  const experiments = flowAPI.experiments;
  const t = flowAPI.translations.t;

  const isConvertNavigationBarToWidgetExperimentEnabled = experiments.enabled(
    'specs.restaurants.convertNavigationBarToWidget'
  );

  const setNavigationWidgetName = (componentBuilder: ConnectedComponentsBuilder) => {
    componentBuilder.set({ displayName: t('menu-navigation.element.label') });
  };

  const addNavigationPanelGfpp = (componentBuilder: ConnectedComponentsBuilder) => {
    componentBuilder
      .gfpp('mobile')
      .set('mainAction1', {
        label: t('menu-navigation.layout.button'),
        onClick: async (event) => {
          event.preventDefault();
        },
      })
      .set('settings', { behavior: 'HIDE' });
    componentBuilder
      .gfpp()
      .set('mainAction1', {
        label: t('menu-navigation.layout.button'),
        onClick: async (event) => {
          event.preventDefault();
        },
      })
      .set('settings', { behavior: 'HIDE' });
  };

  if (isConvertNavigationBarToWidgetExperimentEnabled) {
    widgetBuilder.configureConnectedComponents(getRole(MENU_NAVIGATION_COMPONENT_IDS.tabs), (componentBuilder) => {
      setNavigationWidgetName(componentBuilder);
      addNavigationPanelGfpp(componentBuilder);
    });

    widgetBuilder.configureConnectedComponents(getRole(MENU_NAVIGATION_COMPONENT_IDS.dropdown), (componentBuilder) => {
      setNavigationWidgetName(componentBuilder);
      addNavigationPanelGfpp(componentBuilder);
      componentBuilder.gfpp('mobile').set('mainAction2', { behavior: 'HIDE' });
      componentBuilder.gfpp().set('mainAction2', { behavior: 'HIDE' });
    });
  }
};
